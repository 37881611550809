import React from 'react';
import { StyledFormCheckbox } from './FormCheckbox.styles';
import { IFormCheckbox } from './FormCheckbox.types';
import Icon from '../../elements/Icon';
import Copy from '../../elements/Copy';
import Container from '../../elements/Container';
import Spacer from '../../elements/Spacer';
import { palette } from '../../colors';

export const FormCheckbox = ({
  dataTestId,
  onChange,
  checked,
  disabled,
  icon,
  label,
  fontFamily,
  showError,
  errorMessage,
  children,
  backgroundColor,
  size = 24,
  padding,
}: IFormCheckbox) => (
  <Container>
    <StyledFormCheckbox
      data-testid={dataTestId}
      $errorBorder={showError}
      $backgroundColor={backgroundColor}
      $size={size}
      $padding={padding}
    >
      <div className="checkbox">
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled ?? false}
          data-testid={`${dataTestId}-checkbox`}
        />
        <span data-testid={`${dataTestId}-tick-icon`} />
      </div>
      <div className="label">
        {icon && (
          <Icon
            dataTestId={`${dataTestId}-icon`}
            name={icon}
            $styles="padding: 0 8px 0 12px"
            $height={size}
            $width={size}
          />
        )}
        {label}
        {children}
      </div>
    </StyledFormCheckbox>
    {errorMessage && showError && (
      <>
        <Spacer height={12} />
        <Copy fontSize={14} color={palette.error[500]} dataTestId={`${dataTestId}--message`}>
          {errorMessage}
        </Copy>
      </>
    )}
  </Container>
);
