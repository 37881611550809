import { getImageProps } from 'next/image';
import {
  ColourSwatches,
  SkeletonLoadingBlock,
  Typography,
  Badge,
  CSS_FONT_WEIGHT,
  TAILWIND_BREAKPOINTS,
  cn,
} from 'uibook';
import { ProductCardContentProps } from './ProductCard.types';
import { cardImageSizes, getCardImageUrl } from './constants';
import { VariantCondition } from '@/graphql/generated/graphql';
import { imgixLoader } from '../../utils/imgixLoader';
import { px2Rem } from 'uibook/utils/px2Rem';

export const ProductCardContent = ({
  productVariantConditions,
  make,
  displayName,
  cardDisplaySubtitle,
  url,
  displayPrice,
  colours,
  shouldDisplayPrices,
  variantSlug,
  isPriorityImage,
  priceSuffix,
}: ProductCardContentProps) => {
  const productCardImageSharedProps = {
    alt: displayName ?? '',
    loader: imgixLoader,
    priority: isPriorityImage,
    quality: 30,
  };

  /**
   * `next/image` likes to create a wide range of `srcSet` values, but we only want to use the
   * initial width and double the width, as we know that the image will be displayed at that width
   * on the page. Mobile is only displayed at the `cardImageSizes.mobile.width` width, and desktop
   * is only displayed at the `cardImageSizes.desktop.width` width.
   *
   * This allows us to reduce the amount of HTML rendered for the PLP page, as we only generate the
   * exact srcSet values that are needed.
   */
  const createOverridingSrcSet = (url: string | undefined, device: 'mobile' | 'desktop') => {
    const initialWidth = cardImageSizes[device].width;

    /** Generate `1x` and `2x` image urls */
    return [initialWidth, initialWidth * 2]
      .map((width) => {
        return [
          imgixLoader({
            src: getCardImageUrl(url, device),
            width: width,
            quality: productCardImageSharedProps.quality,
          }),
          `${width}w`,
        ].join(' ');
      })
      .join(', ');
  };

  const { props: desktopImageProps } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.desktop.width,
    height: cardImageSizes.desktop.height,
    src: getCardImageUrl(url, 'desktop'),
    sizes: `${cardImageSizes.desktop.width}px`,
  });
  const { props: mobileImageProps } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.mobile.width,
    height: cardImageSizes.mobile.height,
    src: getCardImageUrl(url, 'mobile'),
    sizes: `${cardImageSizes.mobile.width}px`,
  });

  /** Use these srcSet values to override the `srcSet` values generated by `next/image` */
  const mobileImageSrcSet = createOverridingSrcSet(url, 'mobile');
  const desktopImageSrcSet = createOverridingSrcSet(url, 'desktop');

  return (
    <div
      data-testid={`desktop-card-${variantSlug}`}
      className="shadow-product-card-content relative flex h-full w-full flex-col items-start justify-center bg-white py-4 pl-32 pr-3 lg:items-center lg:p-7"
    >
      <div className="pb-1 lg:pb-2">
        <Typography bold className="mr-1 inline lg:mr-0 lg:block lg:pb-2 lg:text-center">
          {make}
        </Typography>
        <Typography bold className="inline lg:block lg:text-center">
          {displayName}
        </Typography>
      </div>
      {cardDisplaySubtitle && (
        <Typography variant="body2" className="lg:text-center">
          {cardDisplaySubtitle}
        </Typography>
      )}

      <div className="flex h-full flex-col justify-end">
        <div
          className="absolute left-4 top-1/2 flex h-[var(--img-h)] w-[var(--img-w)] -translate-y-1/2 transform justify-center lg:relative lg:left-auto lg:top-auto lg:mb-3 lg:mt-6 lg:h-[var(--lg-img-h)] lg:w-[var(--lg-img-w)] lg:transform-none lg:justify-center"
          style={{
            '--img-w': `${px2Rem(cardImageSizes.mobile.width)}`,
            '--img-h': `${px2Rem(cardImageSizes.mobile.height)}`,
            '--lg-img-w': `${px2Rem(cardImageSizes.desktop.width)}`,
            '--lg-img-h': `${px2Rem(cardImageSizes.desktop.height)}`,
          }}
        >
          <picture>
            <source
              media={`(min-width: ${TAILWIND_BREAKPOINTS.lg}px)`}
              srcSet={desktopImageSrcSet}
              sizes={desktopImageProps.sizes}
            />
            <img
              {...mobileImageProps}
              srcSet={mobileImageSrcSet}
              alt={productCardImageSharedProps.alt}
              style={{ width: '100%', height: 'auto' }}
            />
          </picture>
        </div>

        {colours.length > 1 ? (
          <ColourSwatches
            dataTestId="colour-swatches"
            colours={colours}
            className="items-start justify-start gap-1.5 pb-3 pt-2 lg:justify-center lg:pb-4 lg:pt-3"
          />
        ) : null}

        <div
          className={cn('flex justify-start gap-2 pb-3 lg:justify-center lg:pb-4', {
            'pt-8 lg:pt-10': colours.length < 2,
          })}
        >
          {[
            {
              condition: VariantCondition.New,
              label: 'Brand New',
              dataTestId: 'condition-badge-new',
            },
            {
              condition: VariantCondition.Refurbished,
              label: 'Refurbished',
              dataTestId: 'condition-badge-refurbished',
            },
          ]
            .filter((item) => productVariantConditions.includes(item.condition))
            .map((item) => (
              <Badge
                key={item.condition}
                type="rounded"
                fontWeight={CSS_FONT_WEIGHT.vars.regular}
                color="lightGrey"
                fontSize={12}
                textTransform="capitalize"
                dataTestId={item.dataTestId}
              >
                {item.label}
              </Badge>
            ))}
        </div>

        {shouldDisplayPrices ? (
          <Typography variant="body2" bold className="lg:type-h4 lg:text-center">
            <span className="text-charcoal-400 lg:type-body2 font-normal">From</span> £
            {displayPrice}/mo{' '}
            {priceSuffix && (
              <span className="text-charcoal-400 lg:type-body2 font-normal">{priceSuffix}</span>
            )}
          </Typography>
        ) : (
          <SkeletonLoadingBlock
            dataTestId={`${variantSlug}-loading`}
            $height={18}
            $borderRadius={5}
          />
        )}
      </div>
    </div>
  );
};
