import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';

const StyledFilterWrapper = styled.div`
  padding: ${px2Rem(24)};
  min-height: 100vh;
  background: white;
  height: fit-content;
  margin-bottom: ${px2Rem(70)};
`;

const StyledFilterWrapperHeader = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: none;
  }
`;

export { StyledFilterWrapper, StyledFilterWrapperHeader };
