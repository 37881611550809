import { Typography } from 'uibook';
import { useProductsContext } from '../../../hooks/useProductsContext';

export const FilterProductCount = () => {
  const { filteredProducts } = useProductsContext();

  return (
    <Typography variant="body2" data-testid="filter-product-count" className="text-charcoal-500">
      {filteredProducts.length} product{filteredProducts.length !== 1 && 's'}
    </Typography>
  );
};
