import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';

const StyledFilterLabel = styled.div<{
  $isSelected: boolean;
  $textTransform?: string;
  $backgroundColor?: string;
  $showClose?: boolean;
  $margin?: string;
}>`
  ${({ $isSelected, $textTransform, $backgroundColor, $showClose, $margin }) => `
    cursor: pointer;
    border-radius: ${px2Rem(16)};
    border: ${px2Rem(1)} solid ${palette.charcoal[300]};
    color: ${palette.charcoal[500]};
    background-color: ${$backgroundColor ?? palette.white};
    flex-shrink: 0;

    ${
      !$showClose &&
      `
      ${
        $isSelected
          ? `
          border: ${px2Rem(1)} solid transparent;
          color: ${palette.white};
          background-color: ${$backgroundColor ?? palette.blue[500]};
          &:hover {
            background-color: ${palette.blue[400]};
          }
      `
          : `
        &:hover {
          background-color: ${palette.charcoal[100]};
          border-color: ${palette.charcoal[200]};
        }
      `
      }
    `
    };

    ${
      $showClose &&
      `
      &:hover {
        background-color: ${palette.charcoal[200]};
        border-color: ${palette.charcoal[400]};
      }
    `
    };
    width: fit-content;
    height: fit-content;
    padding: ${px2Rem(4)} ${px2Rem(12)};
    text-transform: ${$textTransform ?? 'none'};
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(20)};
    letter-spacing: ${$textTransform === 'uppercase' ? px2Rem(1) : 0};
    margin: ${$margin ?? `0 ${px2Rem(12)} ${px2Rem($showClose ? 0 : 12)} 0`};
    display: flex;
    align-items: center;
    white-space: nowrap;
  `}
`;

export { StyledFilterLabel };
