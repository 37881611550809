import { LegacyButton } from 'uibook';
import { useProductsContext } from '../../../hooks/useProductsContext';
import { ResultsButtonContainer } from './styles';

const FilterModalButtons = ({ closeModal }: { closeModal: () => void }) => {
  const { filters, showResultsButton, resetFilter } = useProductsContext();
  const isFiltering = Object.entries(filters).some(([_, val]) => val.length) || showResultsButton;
  const filterCount = Object.values(filters).flat().length;
  const styledFilterCount = `Apply (${filterCount})`;

  return (
    <ResultsButtonContainer isFiltering={isFiltering} data-testid="filter-results-button-wrapper">
      <LegacyButton secondary onClick={resetFilter} dataTestId="clear-all-button">
        Clear All
      </LegacyButton>
      <LegacyButton onClick={closeModal} dataTestId="filter-results-button">
        {filterCount > 0 ? styledFilterCount : 'Apply'}
      </LegacyButton>
    </ResultsButtonContainer>
  );
};

export default FilterModalButtons;
