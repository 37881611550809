import { BaseModal } from 'uibook';
import FilterWrapper from '../FilterWrapper/FilterWrapper';
import { StyledFilterWrapperModal } from './styles';
import { useAppContext } from '../../../hooks/useAppContext';
import { useProductsContext } from '../../../hooks/useProductsContext';
import { track } from '../../../integrations/segment/segmentTracking';
import FilterModalButtons from '../FilterModalButtons/FilterModalButtons';

const FilterWrapperModal = () => {
  const { modalOpen, setModalOpen, merchantDomain } = useAppContext();
  const { setShowResultsButton } = useProductsContext();

  const closeModal = () => {
    setModalOpen(null);
    setShowResultsButton(false);
    track('View Filter And Sort Results Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <StyledFilterWrapperModal>
      <BaseModal
        modalOpen={modalOpen === 'filters'}
        closeModal={closeModal}
        offsetTop={64}
        mobileWidth="calc(100% - 40px)"
        desktopWidth="calc(100% - 40px)"
        maxWidth={500}
      >
        <FilterWrapper />
      </BaseModal>
      <FilterModalButtons closeModal={closeModal} />
    </StyledFilterWrapperModal>
  );
};

export default FilterWrapperModal;
