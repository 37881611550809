import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';

const StyledFilterBottomFade = styled.div`
  width: 100%;
  height: ${px2Rem(36)};
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
`;

export { StyledFilterBottomFade };
