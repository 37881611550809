import { ASSETS_SVGS_BASE_URL } from '../../constants';
import { cn } from '../../utils/cn';

type NativeSelectOptionType = string | { value: string; label: string };

/**
 * Check if the `option` is a string, so other components can handle the `value/label` option
 * correctly.
 */
const optionIsString = (option: NativeSelectOptionType): option is string => {
  return typeof option === 'string';
};

type NativeSelectOptionProps = {
  option: NativeSelectOptionType;
};

/** Thin wrapper around the `<option>` element, which handles string, number and value/label objects */
const NativeSelectOption = ({ option }: NativeSelectOptionProps) => {
  if (optionIsString(option)) {
    return <option value={option}>{option}</option>;
  }

  return <option value={option.value}>{option.label}</option>;
};

type NativeSelectProps = React.ComponentPropsWithoutRef<'select'> & {
  /**
   * Options can be an array of strings, or a value/label object.
   *
   * ```tsx
   * <NativeSelect options={['one', 'two', 'three']} />
   * <NativeSelect
   *   options={[
   *     {
   *       value: '1',
   *       label: 'Option 1',
   *     },
   *     {
   *       value: '2',
   *       label: 'Option 2',
   *     },
   *     {
   *       value: '3',
   *       label: 'Option 3',
   *     },
   *   ]}
   * />
   * ```
   *
   * If no `options` are passed, you can just pass the `<option>` elements as `children`:
   *
   * ```tsx
   * <NativeSelect>
   *   <option value="1">Option 1</option>
   *   <option value="2">Option 2</option>
   *   <option value="3">Option 3</option>
   * </NativeSelect>;
   * ```
   */
  options?: NativeSelectOptionType[];
  /** If a string is set, this will be the initial unselected option at the top of the options list */
  placeholderOption?: string;
  ref?: React.ComponentPropsWithRef<'select'>['ref'];
};

/** Native HTML `<select>` element, styled to match the design system */
export const NativeSelect = ({
  className,
  options,
  placeholderOption,
  children,
  ...selectProps
}: NativeSelectProps) => {
  return (
    <select
      className={cn(
        'border-charcoal-300 text-charcoal-500 type-body1 hover:border-charcoal-500 hover:bg-charcoal-100 block h-14 w-full cursor-pointer appearance-none border bg-white bg-[length:1.5rem_auto] bg-[position:right_1rem_center] bg-no-repeat py-0.5 pl-3 pr-14 focus:outline-none',
        className,
      )}
      style={{
        backgroundImage: `url('${ASSETS_SVGS_BASE_URL}/chevron-down.svg')`,
      }}
      {...selectProps}
    >
      {placeholderOption && (
        <option value="" disabled>
          {placeholderOption}
        </option>
      )}
      {options
        ? options.map((option) => (
            <NativeSelectOption
              key={optionIsString(option) ? option : option.value}
              option={option}
            />
          ))
        : children}
    </select>
  );
};
