import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { palette } from 'uibook';

const ResultsButtonContainer = styled.div<{ isFiltering: boolean }>`
  ${({ isFiltering }) => `
    display: flex;
    width: 100%;
    position: fixed;
    bottom: ${isFiltering ? 0 : '-100%'};
    height: 100px;
    z-index: 99999;
    background-color: ${palette.white};
    padding: ${px2Rem(24)};
    transition: bottom 0.5s linear;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.04), 0px -20px 25px -5px rgba(0, 0, 0, 0.10);

    button {
      max-width: ${px2Rem(238)};
      &:last-of-type {
        margin-left: ${px2Rem(24)};
      }
    }
  `}
`;

export { ResultsButtonContainer };
