import React from 'react';
import { StyledFilterLabel } from './FilterLabel.styles';
import { IFilterLabel } from './FilterLabel.types';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';

export const FilterLabel = ({
  copy,
  isSelected,
  onSelect,
  textTransform,
  showClose,
  backgroundColor,
  dataTestId,
  margin,
}: IFilterLabel) => (
  <StyledFilterLabel
    data-testid={dataTestId}
    $backgroundColor={backgroundColor}
    $textTransform={textTransform}
    $isSelected={isSelected}
    onClick={onSelect}
    $showClose={showClose}
    $margin={margin}
  >
    {copy}
    {showClose && (
      <Icon
        dataTestId={`${dataTestId}-close`}
        name="FilterLabelClose"
        $height={12}
        $width={12}
        $margin={`0 0 0 ${px2Rem(4)}`}
      />
    )}
  </StyledFilterLabel>
);
