import { cn } from '../../utils/cn';

type ColourSwatchesProps = {
  colours: `#${string}`[];
  dataTestId?: string;
  className?: string;
};

export const ColourSwatches = ({ colours, dataTestId, className }: ColourSwatchesProps) => (
  <div
    data-testid={dataTestId}
    className={cn('flex w-full flex-wrap justify-center gap-3', className)}
  >
    {colours.map((backgroundColor, i) => (
      <span
        className="border-charcoal-200 h-3 w-3 rounded-full border"
        data-testid={`${backgroundColor}-${i}`}
        key={`${backgroundColor}-${i}`}
        style={{ backgroundColor }}
      />
    ))}
  </div>
);
