import {
  StyledSortMenu,
  StyledSortMenuProductCountWrapper,
  StyledSortMenuSortByLabel,
  StyledSortSelectWrapper,
} from './styles';
import { useProductsContext } from '../../../hooks/useProductsContext';
import { ALL_SORT_OPTIONS } from '../utils';
import { FilterProductCount } from '../FilterProductCount/FilterProductCount';
import { NativeSelect, Typography } from 'uibook';

const SortMenu = () => {
  const { handleSortProductsChange, activeSortLabel } = useProductsContext();

  return (
    <StyledSortMenu>
      <StyledSortMenuProductCountWrapper>
        <FilterProductCount />
      </StyledSortMenuProductCountWrapper>
      <StyledSortMenuSortByLabel>
        <Typography bold>Sort by</Typography>
      </StyledSortMenuSortByLabel>
      <StyledSortSelectWrapper data-testid="sort-menu">
        <NativeSelect
          data-testid="sort-menu-select"
          options={ALL_SORT_OPTIONS}
          defaultValue={activeSortLabel?.value}
          onChange={(event) => {
            const option = ALL_SORT_OPTIONS.find((opt) => opt.value === event.target.value);
            if (option) {
              handleSortProductsChange(option);
            }
          }}
          /** Temporary font-size override to match the existing PLP font-size */
          className="type-body2"
        />
      </StyledSortSelectWrapper>
    </StyledSortMenu>
  );
};

export default SortMenu;
